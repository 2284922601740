:root {
  --primary-color: #e01933;
  --light-primary-color: #7c9f7a;
  --dark-color: #403f3d;
  --light-color: #f6fbf6;
  --grey-color: #787878;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

/* Header */
header {
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow: hidden;
  background: var(--primary-color);
}

.primary-bg {
  background: var(--primary-color);
}

.navbar-brand {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1.8rem;
}

.topHeader {
  background-color: var(--light-primary-color);
  padding: 8px 0px;
}

.topNav {
  width: 100%;
}

.topNav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row-reverse;
}

.topNav ul li {
  margin: 0 0 0 20px;
}

.topNav ul li a {
  color: var(--light-color);
  font-size: 14px;
  text-decoration: none;
  line-height: 22px;
}

.home-img {
  position: relative;
}

.home-img img {
  width: 70%;
  position: relative;
  z-index: 9;
}

.home-img .img-bg {
  position: absolute;
  background: url(assets/images/background-home.png) no-repeat;
  width: 300px;
  height: 301px;
  z-index: 1;
  top: 150px;
  right: 0px;
}

.offer {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: var(--primary-color);
}

.offer h3 {
  color: var(--primary-color);
  line-height: 1.5;
}

.offer p {
  color: var(--primary-color);
  margin: 0px 25px;
}

.offer .btn-color {
  background-color: var(--primary-color) !important;
  padding: 13px 20px;
  color: var(--light-color);
}

.navbar-nav li {
  padding: 0px 15px;
}

.navbar-nav li a {
  text-transform: capitalize;
  font-size: 1rem;
  color: #e01933;
  position: relative;
  font-weight: normal;
}

.navbar-nav li a:before {
  content: "";
  position: absolute;
  top: 100%;
  bottom: 50px;
  width: 0px;
  height: 3px;
  background: #e01933;
  transition: all 0.3s linear;
}

.navbar-nav li a:hover:before,
.navbar-nav li a.active:before {
  width: 0%;
}

.main-head-display {
  text-shadow: 0px 0px 3px #1c3c1b;
  font-size: 2rem;
  margin-bottom: 30px;
}

.lead-text {
  font-size: 1.1rem !important;
}

.svg-img {
  margin-top: -180px;
  height: 225px;
}

.brands img {
  width: 65%;
}

/* Header */

@media only screen and (max-width: 600px) {
  .svg-img {
    margin-top: -50px;
    height: 75px;
  }
}

/* Main Box */
.banner_box {
  height: 170px;
  background-size: 100%;
}

.banner_box:hover {
  background-size: 102%;
  transition: all 0.3s linear;
}

.banner_box .box {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.banner_box .box:hover {
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.3s linear;
}

.banner_box h3 {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  line-height: 1.18;
}

/* Main Box */

.head-2 {
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--dark-color);
  margin: 30px 0px;
}

.card {
  border: none !important;
}

.card img {
  border-radius: 0px;
}

.text-primary {
  color: #1976d2 !important;
}

.svg-img .green-bg {
  background: var(--primary-color);
  height: 200px;
  width: 100%;
}

.sticky-bg {
  background-color: var(--primary-color);
  top: 100px;
}

.sticky-bg-2 {
  background-color: var(--light-color);
  top: 100px;
}

.content-block h1,
.content-block h2 {
  color: var(--dark-color);
  letter-spacing: 0.5px;
}

.content-block h2 {
  font-size: 1.6rem;
  margin: 30px 0 10px;
}

.content-block p {
  color: var(--grey-color);
  font-size: 1rem;
  letter-spacing: 0.3px;
}

.content-block a {
  color: var(--primary-color);
  text-decoration: underline;
}

.content-block a:hover {
  text-decoration: none;
  color: var(--primary-color);
}

.content-block ul li {
  color: var(--grey-color);
  font-size: 1rem;
  margin: 0 0 5px 20px;
}

.svg-img {
  background-color: #ffffff;
}

.card-block .card-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: var(--dark-color);
  letter-spacing: 1px;
  font-family: serif;
}

.card-block .card-text {
  color: var(--grey-color);
  font-size: 1rem;
  line-height: 1.9;
}

.widget {
  background-color: var(--primary-color);
  top: 0px;
  padding: 0px 0px 0 0;
  position: sticky;
  z-index: 9;
}

.widget.right-widget {
  background-color: #ffffff;
}

.widget iframe {
  height: 430px;
}

.widget p {
  color: var(--primary-color);
  font-size: 13px;
  margin: 0px;
  padding: 0px;
}

.card-img-overlay {
  background-color: rgb(1 21 1 / 40%);
  transition: all 0.3s linear;
}

.card-img-overlay:hover {
  background-color: rgb(1 21 1 / 80%);
}

.traveCard .card-img-box {
  width: 100%;
  max-height: 290px;
  overflow: hidden;
}

.traveCard .card-title {
  font-size: 1rem;
}

.traveCard .card-text {
  font-size: 0.9rem;
  color: var(--grey-color);
}

.btn-amazon {
  border: 1px solid #ff9900 !important;
  background: #f0c14b !important;
  border-color: #a88734 #9c7e31 #846a29 !important;
  color: #111 !important;
  border-radius: 0px !important;
}

.svg-img-right {
  margin-top: -33px;
  width: 100%;
}

/* Responsive Css */

@media only screen and (max-width: 600px) {
  .widget {
    position: static;
    background-color: var(--primary-color);
  }

  .widget p {
    margin-bottom: 120px;
  }

  .svg-img-right {
    margin-top: -7px;
  }

  .widget iframe {
    height: 340px;
  }

  .card-img-overlay {
    position: static !important;
  }
}

/* Responsive Css */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.btn-frd {
  background: #e01933;
  border: 1px solid #e01933;
  color: #fff;
  font-size: 1rem;
  padding: 20px 25px;
  border-radius: 3px;
  font-weight: bold;
  transition: all ease-in-out 0.3s;
}
.btn-frd:hover {
  background: #fff;
  color: #e01933;
  text-decoration: none;
}
